import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import SEO from '../../components/seo';
import Layout from '../../components/fr';

import FeaturesMD from '../../components/features/features-fr';
import TechMD from '../../components/tech/tech-fr';
import AwardsMD from '../../components/awards/awards-fr';

export const query = graphql`
  query($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      excerpt
      frontmatter {
        title
        lead
        available
        discount
        id
        shopAddress
        role
        price
        testimonial
        testimonial_author
        videos
        images {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
              src
            }
          }
        }
        avatar {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const Product = ({ data, pageContext }) => {
  const { next, prev } = pageContext;
  const { html, frontmatter, excerpt } = data.markdownRemark;
  const {
    title,
    lead,
    price,
    role,
    id,
    shopAddress,
    avatar,
    images,
    videos,
    available,
    discount,
    testimonial,
    testimonial_author,
    tags,
    path,
  } = frontmatter;

  return (
    <Layout data={data} location={typeof window !== 'undefined' ? location : null}>
      <SEO
        title={`${title} - Furlan Snowboards`}
        description={lead || excerpt || ' '}
        pathname={path}
        article
        keywords={['snowboard', 'boards', 'polyvalence']}
      />
      <div className="productPage">
        <div className="alert alert-secondary" role="alert">
          <center>🎁 <strong>-300€ SI TU TROUVES UN POTE QUI ACHETE UNE 2EME BOARD AVEC TOI</strong> 🎁<br />🎉 <strong>Code: yepoto</strong> -150€ sur chaque board à la commande! 🎉</center>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <Img fluid={avatar.childImageSharp.fluid} alt="Furlan Snowboards" />
              {!testimonial == '' && (
                <blockquote className="blockquote my-3 pr-1 pr-md-4">
                  <p className="mb-2 lead">
                    {testimonial}
                    <br />
                  </p>
                  <footer className="blockquote-footer">
                    <cite title={testimonial_author}>{testimonial_author}</cite>
                  </footer>
                </blockquote>
              )}
            </div>
            <div className="col-md-7">
              <h1 className="mt-5">{title}</h1>
              <p>
                {!price == '0' && available == 'yes' ? (
                  <a href={shopAddress} className="btn btn-warning">
                    <strong>Acheter</strong>
                  </a>
                ) : (
                  <Link to="/fr/contact-us">
                    <button className="btn btn-warning btn-lg">Contact us</button>
                  </Link>
                )}
                {discount == 'yes' && (
                  <span className="badge badge-pill badge-warning badge-lg">
                    -40%
                  </span>
                )}
                {!price == '0' ? (
                  <span className="badge badge-pill badge-secondary badge-lg">
                    {price}&euro;
                  </span>
                ) : (
                  <span className="badge badge-pill badge-secondary badge-lg">
                    Club offer
                  </span>
                )}
                <span className="badge badge-pill badge-secondary badge-lg">
                  {role}
                </span>
                {available == 'no' && (
                  <span className="badge badge-pill badge-warning badge-lg">
                    Indisponible
                  </span>
                )}
              </p>
              <p className="lead">{lead}</p>
              <div
                className="content mt-3"
                dangerouslySetInnerHTML={{ __html: html }}
              ></div>
            </div>
          </div>
          <div className="row no-gutter mt-5 mb-5">
            {images &&
              images.map((image, index) => (
                <div className="col-md-6 p-1" key={index}>
                  <a
                    target="_blank"
                    href={image.childImageSharp.fluid.src}
                    rel="noopener noreferrer"
                  >
                    <Img fluid={image.childImageSharp.fluid} />
                  </a>
                </div>
              ))}
            {videos &&
              videos.map((video, index) => (
                <div
                  className="col-md-6 embed-responsive embed-responsive-16by9"
                  key={index}
                >
                  <iframe
                    height="600"
                    width="100%"
                    title="video"
                    className="embed-responsive-item p-1"
                    src={video}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              ))}
          </div>
        </div>
        {/* <div className="container page-links">
          <div className="row">
            <div className="col-6">
              {prev && (
                <Link to={prev.frontmatter.path}>
                  Previous
                  <h3>{prev.frontmatter.title}</h3>
                </Link>
              )}
            </div>
            <div className="col-6 text-right">
              {next && (
                <Link to={next.frontmatter.path}>
                  Next
                  <h3>{next.frontmatter.title}</h3>
                </Link>
              )}
            </div>
          </div>
              </div> */}
        <hr />
        <FeaturesMD />
        <hr />
        <TechMD />
        <hr />
        <AwardsMD />
      </div>
    </Layout>
  );
};

export default Product;

Product.propTypes = {
  pageContext: PropTypes.shape({
    prev: PropTypes.object,
    next: PropTypes.object,
  }).isRequired,
  data: PropTypes.object.isRequired,
};
